import { HttpClient, HttpResponse } from '@angular/common/http';
import { Injectable, inject } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from '../environments/environment';
import {
    AttendanceByCourse,
    AttendanceByCourseSearch,
    CourseStudentAttendancesByDateDTO,
} from '../model/attendance-by-course.model';
import {
    AttendanceByStudent,
    AttendanceByStudentSearch,
    StudentAttendancesByDateDTO,
} from '../model/attendance-by-student.model';
import {
    CalendarEvent,
    CalendarEventMessage,
    CalendarEventSearch,
    CustomCalendarEvent,
    IPageableCalendarEvent,
    ModeratorCourseEventsSearch,
    RecentMeeting,
} from '../model/calendar-event.model';
import {
    CourseScheduleBoardDTO,
    CourseScheduleDailyBoardDTO,
    CustomCourseEvent,
} from '../model/course-schedule-board.model';
import { CourseScheduleSearchDTO } from '../model/course-schedule.model';
import { AttendanceInfo } from '../model/course-student-attendance.model';
import { ICourse } from '../model/course.model';
import { KeyValueDTO } from '../model/keyvalue.model';
import { Meeting, Subscriber } from '../model/meetings-model.model';
import { IPage } from '../model/page.model';
import { TimePeriod } from '../model/time-periods.model';
import { CourseEventSearch } from '../model/time-table.model';
import { logRequest } from '../util/request-util';
import { httpParams } from '../util/utils';

@Injectable({
    providedIn: 'root',
})
export class CalendarEventService {
    private http = inject(HttpClient);

    private _activeTimePeriod: TimePeriod;

    get baseUrl() {
        return environment.apiUrl + '/school-service/api/calendar-event';
    }

    set activeTimePeriod(activeTimePeriod: TimePeriod) {
        this._activeTimePeriod = activeTimePeriod;
    }
    get activeTimePeriod() {
        return this._activeTimePeriod;
    }

    getRecentEvents(schoolId: string): Observable<CalendarEvent[]> {
        return this.http.get<CalendarEvent[]>(this.baseUrl + '/school/' + schoolId + '/recent');
    }

    getTodayEvents(searchDTO: CalendarEventSearch): Observable<HttpResponse<CalendarEvent[]>> {
        return this.http.post<CalendarEvent[]>(this.baseUrl + '/daily', searchDTO, {
            observe: 'response',
        });
    }

    getDateRange(searchDTO: CalendarEventSearch, studentId?: string): Observable<HttpResponse<CalendarEvent[]>> {
        return this.http.post<CalendarEvent[]>(this.baseUrl + '/date-range/' + studentId, searchDTO, {
            observe: 'response',
        });
    }

    getWeekEvents(searchDTO: CalendarEventSearch): Observable<HttpResponse<CalendarEvent[]>> {
        return this.http.post<CalendarEvent[]>(this.baseUrl + '/weekly', searchDTO, {
            observe: 'response',
        });
    }

    getDailyCourseEvents(searchDTO: CalendarEventSearch): Observable<HttpResponse<CourseScheduleDailyBoardDTO>> {
        return this.http.post<CourseScheduleDailyBoardDTO>(this.baseUrl + '/daily-course-schedule', searchDTO, {
            observe: 'response',
        });
    }

    getWeeklyCourseEvents(searchDTO: CalendarEventSearch): Observable<HttpResponse<CourseScheduleBoardDTO>> {
        return this.http.post<CourseScheduleBoardDTO>(this.baseUrl + '/weekly-course-schedule', searchDTO, {
            observe: 'response',
        });
    }

    getMonthEvents(searchDTO: CalendarEventSearch): Observable<HttpResponse<CalendarEvent[]>> {
        return this.http.post<CalendarEvent[]>(this.baseUrl + '/monthly', searchDTO, {
            observe: 'response',
        });
    }

    generateCalendarCourseEvents(searchDTO: CourseEventSearch): Observable<HttpResponse<any>> {
        return this.http.post<any>(this.baseUrl + '/generate-event', searchDTO, {
            observe: 'response',
            headers: logRequest(),
        });
    }

    deleteCourseEvents(searchDTO: CourseEventSearch): Observable<HttpResponse<any>> {
        return this.http.patch<any>(this.baseUrl + '/delete-event', searchDTO, {
            observe: 'response',
            headers: logRequest(),
        });
    }

    getMeetingByCalendarEventIdForOnlineCourse(eventId: string): Observable<Meeting> {
        return this.http.get<Meeting>(this.baseUrl + '/' + eventId + '/meeting');
    }

    sendMessageToMembers(eventId: string, message: CalendarEventMessage): Observable<HttpResponse<any>> {
        return this.http.post<any>(this.baseUrl + '/' + eventId + '/course-message-send', message, {
            observe: 'response',
        });
    }

    getRecentCourseMeetings(id: string, description?: string): Observable<RecentMeeting[]> {
        return this.http.get<RecentMeeting[]>(
            this.baseUrl + '/recent/courses/' + id + (description ? '/' + description : '')
        );
    }

    cancelEvent(id: string): Observable<any> {
        return this.http.get<any>(this.baseUrl + '/cancel/' + id, {
            headers: logRequest(),
        });
    }

    getModeratorCourses(searchDTO: ModeratorCourseEventsSearch): Observable<HttpResponse<CalendarEvent[]>> {
        return this.http.post<CalendarEvent[]>(this.baseUrl + '/moderator-courses', searchDTO, {
            observe: 'response',
        });
    }

    addModerator(id: string, courseEventIds: string[]): Observable<HttpResponse<any>> {
        return this.http.patch(this.baseUrl + '/' + id + '/add-moderator', courseEventIds, {
            observe: 'response',
            headers: logRequest(),
        });
    }

    removeModerator(id: string, courseEventIds: string[]): Observable<HttpResponse<any>> {
        return this.http.patch(this.baseUrl + '/' + id + '/remove-moderator', courseEventIds, {
            observe: 'response',
            headers: logRequest(),
        });
    }
    changeModerators(moderators: string[], courseEventIds: string[], schoolId: string): Observable<HttpResponse<any>> {
        return this.http.post(
            this.baseUrl + '/change-moderators',
            { moderators, courseEventIds, schoolId },
            { observe: 'response', headers: logRequest() }
        );
    }
    getCoursesByCalendarEventId(id: string): Observable<HttpResponse<ICourse>> {
        return this.http.get<ICourse>(this.baseUrl + '/' + id + '/course', {
            observe: 'response',
        });
    }

    createCustomCalendarEvent(dto: CustomCalendarEvent): Observable<HttpResponse<CalendarEvent>> {
        return this.http.post<CalendarEvent>(this.baseUrl + '/custom', dto, {
            observe: 'response',
            headers: logRequest(),
        });
    }

    updateCustomCalendarEvent(dto: CustomCalendarEvent): Observable<HttpResponse<CalendarEvent>> {
        return this.http.put<CalendarEvent>(this.baseUrl + '/custom', dto, {
            observe: 'response',
            headers: logRequest(),
        });
    }

    deleteCustomCalendarEvent(id: string): Observable<HttpResponse<any>> {
        return this.http.delete(this.baseUrl + '/custom/' + id, {
            observe: 'response',
            headers: logRequest(),
        });
    }

    getMembersByCalendarEventId(id: string): Observable<HttpResponse<Subscriber[]>> {
        return this.http.get<Subscriber[]>(this.baseUrl + '/' + id + '/members', {
            observe: 'response',
        });
    }

    getCourseSchedule(
        searchDTO: CourseScheduleSearchDTO,
        params?: any
    ): Observable<HttpResponse<IPage<IPageableCalendarEvent>>> {
        return this.http.post<IPage<IPageableCalendarEvent>>(this.baseUrl + '/course-schedule', searchDTO, {
            observe: 'response',
            params: httpParams(params),
        });
    }

    updateStudentAttendance(
        id: string,
        attendanceInfo: AttendanceInfo[],
        complete: boolean
    ): Observable<HttpResponse<any>> {
        return this.http.patch(this.baseUrl + '/' + id + '/update-attendance/' + complete + '/true', attendanceInfo, {
            observe: 'response',
            headers: logRequest(),
        });
    }

    getStudentEventsForAttendance(
        searchDTO: AttendanceByStudentSearch
    ): Observable<HttpResponse<StudentAttendancesByDateDTO[]>> {
        return this.http.post<StudentAttendancesByDateDTO[]>(
            this.baseUrl + '/student-events-for-attendance',
            searchDTO,
            {
                observe: 'response',
            }
        );
    }

    getCourseEventsForAttendance(
        searchDTO: AttendanceByCourseSearch
    ): Observable<HttpResponse<CourseStudentAttendancesByDateDTO[]>> {
        return this.http.post<CourseStudentAttendancesByDateDTO[]>(
            this.baseUrl + '/course-student-events-for-attendance',
            searchDTO,
            {
                observe: 'response',
            }
        );
    }

    getCourseEventsForAttendanceCourseList(
        searchDTO: AttendanceByCourseSearch
    ): Observable<HttpResponse<KeyValueDTO[]>> {
        return this.http.post<KeyValueDTO[]>(this.baseUrl + '/events-attendance-course-list', searchDTO, {
            observe: 'response',
        });
    }

    createAttendanceByStudent(
        list: AttendanceByStudent[],
        forceSaveToAttendance: boolean
    ): Observable<HttpResponse<any>> {
        return this.http.post<any>(this.baseUrl + '/create-attendance-for-student/' + forceSaveToAttendance, list, {
            observe: 'response',
            headers: logRequest(),
        });
    }

    createAttendanceByCourse(
        list: AttendanceByCourse[],
        forceSaveToAttendance: boolean
    ): Observable<HttpResponse<any>> {
        return this.http.post<any>(this.baseUrl + '/create-attendance-by-course/' + forceSaveToAttendance, list, {
            observe: 'response',
            headers: logRequest(),
        });
    }

    getNextCourseCalendarEventByCourseId(courseId: string): Observable<HttpResponse<CalendarEvent>> {
        return this.http.get<CalendarEvent>(this.baseUrl + '/next-course-calendar-event/' + courseId, {
            observe: 'response',
        });
    }

    getAttendanceFromPreviousLesson(id: string): Observable<HttpResponse<AttendanceInfo[]>> {
        return this.http.get<AttendanceInfo[]>(this.baseUrl + '/' + id + '/previous-lesson-attendance', {
            observe: 'response',
        });
    }

    createCustomCourseEvent(customCourseEvent: CustomCourseEvent): Observable<HttpResponse<CalendarEvent>> {
        return this.http.post<CalendarEvent>(this.baseUrl + '/create-custom-course-event', customCourseEvent, {
            observe: 'response',
            headers: logRequest(),
        });
    }
    deleteCustomCourseEvent(id: string): Observable<HttpResponse<any>> {
        return this.http.delete(this.baseUrl + '/custom-course-event/' + id, {
            observe: 'response',
            headers: logRequest(),
        });
    }

    get(id: string): Observable<HttpResponse<CalendarEvent>> {
        return this.http.get<CalendarEvent>(this.baseUrl + '/' + id, {
            observe: 'response',
        });
    }

    getCourseEvenetOnADay(courseId: string, date: Date): Observable<HttpResponse<CalendarEvent[]>> {
        return this.http.post<any>(this.baseUrl + '/course-event-by-date/' + courseId, date, {
            observe: 'response',
        });
    }

    getByIds(ids: string[]): Observable<HttpResponse<CalendarEvent[]>> {
        return this.http.post<CalendarEvent[]>(this.baseUrl + '/events', ids, {
            observe: 'response',
        });
    }
}
